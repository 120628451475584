// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');


$primary: #29aae1; /* MAIN COLOR */
$secondary: #231f20; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-size: 1.5em;
	font-family: 'Josefin Sans', sans-serif;

}
nav {
	z-index: 1000;
	background: $primary;
}
.navbar-default {
	// background: $primary
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: $blk;


		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $secondary;
	    	color: lighten($secondary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
// start sections
h1 {
	font-size: 2.5em;
	font-family: 'Abril Fatface', cursive;

}

.cta {
	background: $wht;
	padding: 25px 35px;
	margin: 5px;
	font-size:1.2em;
}

section {
	padding: 50px 0;
	position: relative;
	text-align: center;
}
.section-a {
	background:url('../img/bg1.jpg')no-repeat top center/cover fixed;
	// height: 100vh;
	margin-top: 100px;
	.logo-section-a {
		margin-top: 15%;
		@media (max-width: 767px){
		}
	}
	@media(max-width:767px){
		margin-top: 70px;
	}
}
.section-b {
	background:url('../img/bg2.jpg')no-repeat top center/cover fixed;
	padding: 250px 0;
	.text-container {
		padding: 50px;
		background: rgba(41,170,225,.8);
	}
	h1 {
		color: $wht;
	}
}
.section-c {
	background:url('../img/bg3.jpg')no-repeat top center/cover fixed;
	padding: 250px 0;
	@media(max-width:767px){
		padding: 100px 0;
	}
	.text-container {
		padding: 50px;
		background: rgba(255,255,255,.5);
	}
	h1 {
		color: $secondary;
		font-size: 2.5em;
		text-transform: uppercase;
	}
	.cta {
		background: $secondary;
		color: $wht;
	}
}
.section-d {
	background:url('../img/bg4.jpg')no-repeat top center/cover fixed;
	padding: 250px 0;
	color: $wht;
	@media(max-width:767px){	padding: 100px 0;

	}
	.text-container {
		padding: 50px;
		background: rgba(41,170,225,.8);
	}
	p{
		font-size: 2em;
	}
}
// ends sections
/** END LOGIN FORM **/

footer {
	padding: 20px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
